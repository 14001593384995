@import '../../../../components/Card/Card.module.scss';

@value formSection from "../../../../features/CVM88/Form/Form.module.scss";

.profileRoot {
  background-color: var(--secondary-background);
  width: 100%;
  height: 100%;
  align-items: flex-start;
}
.backBtn {
  z-index: 1;
}
.heading {
  background-color: var(--main-bg);
  margin-bottom: 40px;
}

.heading,
.backBtn {
  padding-top: 36px;
  padding-bottom: 36px;
}

.edition {
  :global(.formSection) > :last-child {
    @extend .root;
    margin-bottom: 24px;
  }
}
