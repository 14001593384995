.root {
  display: flex;
  height: 100%;
  .main {
    padding: var(--screen-padding);
    flex: 1;
  }
}

.heading {
  background-color: transparent;
}

.resumesContainer {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  > * {
    min-width: 150px;
  }
}

.finished {
  display: flex;
  align-items: center;
  flex-direction: column;
}
