.row {
  display: flex;
  align-items: center;
  > :first-child {
    max-width: 485px;
  }
  > :last-child {
    margin-left: auto;
    white-space: nowrap;
  }
}
