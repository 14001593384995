@import './Home.module.scss';

.okenBanner {
  min-height: 479px;
  background-position: right;
  background-color: black;
  > * {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    height: 100%;
    flex-shrink: 0;
    background-position: right;
  }

  @media screen and (min-width: 900px) {
    --oken-banner-offset: -300px
  }
}
