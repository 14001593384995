.row {
  display: flex;
  align-items: center;
  gap: 18px;
  > :last-child {
    margin-left: auto;
  }
}

.wallet {
  margin-top: 4px;
  background-color: var(--primary-color);
}

.icon {
  border: 1px solid var(--svg-color);
  padding: 4px;
  border-radius: 12px;
  overflow: visible;
}

.status {
  display: flex;
  align-items: center;
  &.complete {
    color: var(--primary-color);
    --svg-color: var(--primary-color);
  }
  &.incomplete {
    color: #C27035;
    --svg-color: #C27035;
  }
}