.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  .root {
    margin: 54px 0px;

    svg {
      max-width: 160px;
    }
  }
}
