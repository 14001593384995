/* Lexend */
@font-face {
  font-family: 'Lexend';
  src: url('../assets/fonts/Lexend/Lexend-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lexend';
  font-weight: bold;
  src: url('../assets/fonts/Lexend/Lexend-Bold.ttf') format('truetype');
}

/* Inter */
@font-face {
  font-family: 'Inter';
  src: url('../assets/fonts/Inter/Inter-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: bold;
  src: url('../assets/fonts/Inter/Inter-Bold.ttf') format('truetype');
}

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  src: url('../assets/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-weight: bold;
  src: url('../assets/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
}
